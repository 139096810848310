<template>
  <div id="user-controls">
    <div v-if="!loggedIn" class="login">
      <a :href="googleOauthUrl">
        Sign-in with Google
      </a>
    </div>
    <ul v-if="loggedIn">
      <li>
        <a>
          {{ userInfo.username }}
        </a>
      </li>
      |
      <li>
        <a href="/logout">
          Logout
        </a>
      </li>
    </ul>
    
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    data () {
      return {
        googleOauthUrl: '',
        loggedIn: false,
        userInfo: {}
      };
    },
    methods: {
      async getGoogleOauthUrl() {
        const { data } = await axios.get(process.env['VUE_APP_STRAPI_API_URL'] + '/strapi-google-auth/init');
        this.googleOauthUrl = data.url;
      },
      async getUserInfo() {

        const { data } = await axios.get(process.env['VUE_APP_STRAPI_API_URL'] + '/strapi-google-auth/me', {headers: {
               Authorization: 'Bearer ' + this.loggedIn
            }});
        this.userInfo = data;
      }
    },
    beforeMount() {
      this.loggedIn = this.$cookies.get('user-jwt');
      // console.log(this.loggedIn)
      this.getGoogleOauthUrl();
      if (this.loggedIn) this.getUserInfo();
    }
  };
</script>

<style scoped>

  li {
    display: inline-block;
  }
  
  #user-controls {
    background-color: rgba(255,255,255,0.75);
    min-height: 2.5em;
    position: fixed;
    top:0;
    width:100vw;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
