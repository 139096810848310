<template>
  <MessageDialog ref="message"></MessageDialog>
  <br/>
  <br/>
  <div :style="{height:((messageOffset + headerOffset)+'px')}" id="message-offset"></div>
  <div class="property-header">
    
    <h1>
      <a @click="$router.go(-1)"><sup>&#8629;</sup></a>&#xa0;
      {{ this.$route.params.id }}
    </h1>
  </div>
  <ul>
  <FixturesList
    @editFixture="editFixture"
    @deleteFixture="deleteFixture"
    :fixtures="fixtures"
  ></FixturesList>
  <li class="add-fixture" v-show="fixtures.data"><a @click="openEdit()">Add Fixture</a></li>
  </ul>
  <div v-if="editing" class="edit-fixture">
    <div v-if="decoding" class="decoding-loading">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
    <div @click="clearAndCloseEdit()" class="edit-fixture-background"></div>
    <form @submit.prevent="onSubmit">
      <!-- <span>{{edits}}</span> -->
      <label for="make">Manufacturer:</label>
      {{edits.Make}}
      <select :name="edits.Make" id="make" v-model="edits.Make" v-on:input="selectedMake">
        <option value="">--Please choose one.--</option>
        <option v-for="make in makes" :key="make.apiName" :value="make.apiName">{{make.formattedName}}</option>
      </select>
      <br/>
      <label v-show="edits.Make !== ''" for="description">Model:</label>
      <input v-show="edits.Make !== ''" id="description" v-model="edits.Model">
      <br/>
      <label v-show="edits.Make !== ''" for="description">Serial:</label>
      <input v-show="edits.Make !== ''" id="description" v-model="edits.Serial">
      <br/>
      <img style="max-height:45vh" v-if="edits.Label.data" :src="
      edits.Label.data.attributes.url[0] === '/' ?
      'http://localhost:1337' + edits.Label.data.attributes.url :
      edits.Label.data.attributes.url
      "/>
      <input v-show="edits.Make !== ''" v-on:change="handleFileUpload($event)" type="file">
      <br/>
      <input v-if="edits.Model !== '' && edits.Serial !== '' && edits.Make !== ''" type="submit" value="Submit">
      <br/>
    </form>
  </div>
</template>

<script>
  // import {watch} from 'vue';
  import FixturesList from '../components/FixturesList.vue';
  import MessageDialog from '../components/MessageDialog.vue';
  import { gql } from '@apollo/client';
  import axios from 'axios';
  import FormData from 'form-data';
  // import * as fs from 'fs';


  function initialEdits() {
    return {
      idd: false,
      id: '',
      Make: '',
      Model: '',
      Serial: '',
      Info: {},
      Label: false
    };
  }

  export default {
    components: {
      FixturesList,
      MessageDialog
    },
    data() {
      return {
        homespyToken: false,
        property: false,
        fixtures: { data: false },
        editing: false,
        decoding: false,
        messageOffset: 0,
        headerOffset: 0,
        edits: initialEdits(),
        makes: []
      }
    },
    beforeMount() {
      this.headerOffset = document.getElementById('user-controls').clientHeight;
    },
    mounted() {
      
      // this.messageOffset = this.$refs.message.offset;
      var self = this;
      if (this.$cookies.get('user-jwt') === null) window.location = '/';
      this.$apollo.query({
        query: gql`
          query getHomespy {
          homespy {
            data {
              id
              attributes {
                Token
              }
            }
          }
        }
        `,
        context: { headers: {
          'Authorization': 'Bearer ' + this.getJwtToken()
          }
        }
      }).then(function(homespy) {
        self.homespyToken = homespy.data.homespy.data.attributes.Token
        self.$apollo.query({
          query: gql`
            query getPropertyId($Title:String!) {
              properties (filters:{Title:{eq:$Title}}){
                data {
                  id
                  attributes {
                    Title
                  }
                }
              }
            }
          `,
          variables: {
            Title: self.$route.params.id
          },
          context: { headers: {
            'Authorization': 'Bearer ' + self.getJwtToken()
            }
          }
        }).then(function(property) {
          self.property = property.data.properties.data[0];

          axios.get('https://homespy.io/api/list/makes/full')
          .then(function(res) {
            // self.makes = res.data.makes;
            for (var k in res.data.makes) {
              self.makes.push(res.data.makes[k]);
              self.makes.sort(function(a, b) {
                var textA = a.apiName;
                var textB = b.apiName;
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              });

            }
          })
        });
      });
    },
    methods: {
      selectedMake: function() {
        if (this.edits.id === '') {
          this.logMessage('info', false, 'Have picture of Appliance\'s Manufacturer Label. "Choose File" to upload.', function(offset) {
                self.messageOffset = offset;
          });
        }
      },
      logMessage: function(type, timeout, message, next) {
        if (typeof this.$refs.message !== 'undefined') this.$refs.message.log(type, timeout, message, next)
      },
      // handleFileUpload: async (e) => {
      handleFileUpload: function(e) {
        var ocrErrored = false;
        var self = this;
        this.decoding = true;
        this.logMessage('info', false, 'Extracting Model and Serial from picture...', function(offset) {
                self.messageOffset = offset;
          });

        const form = new FormData();
        form.append('make', self.edits.Make);
        form.append('imageRaw', e.target.files[0]);
        axios.post(
          'https://homespy.io/api/ocr',
          form,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + self.homespyToken,
              'Accept': 'application/json'
            }
          }
        ).catch(function() {
          ocrErrored = true;
          self.logMessage('err', false, 'Could not extract Model and Serial from picture. Enter Model and Serial manually.', function(offset) {
                self.messageOffset = offset;
          });
          self.decoding = false;
        })
        .then(function(res) {
          if (!ocrErrored) {
            var errored = false;

            if (typeof res.data !== 'undefined' && res.data.result.message === 'success') {

              

              if (res.data.result.ocr.model !== null) self.edits.Model = res.data.result.ocr.model;
              if (res.data.result.ocr.serial !== null) self.edits.Serial = res.data.result.ocr.serial;

              if (res.data.result.ocr.model !== null && res.data.result.ocr.serial !== null) {

                self.idd = true;

              self.logMessage('info', false, 'Model and Serial extracted. Identifying appliance now...', function(offset) {
                self.messageOffset = offset;
          });

              
              
              self.edits.Serial = res.data.result.ocr.serial;

               axios.get('https://homespy.io/api/decode', {
                headers: {
                  'Authorization': 'Bearer ' + self.homespyToken,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                params: {
                  'make': self.edits.Make,
                  'serial': self.edits.Serial,
                  'model': self.edits.Model
                }
              }).then(function(res) {
                if (!errored) {
                  self.logMessage('comp', false, 'Appliance identified! "Submit" to save.', function(offset) {
                self.messageOffset = offset;
          });
                  self.decoding = false;
                  self.edits.Info = res.data.result.decoded;
                }
              }).catch(function() {
                errored = true;
              })
            } else {
              self.logMessage('err', false, 'Model / Serial only partially extracted. Manually enter.', function(offset) {
                self.messageOffset = offset;
          });
              self.decoding = false;
            }
            }
          }
        });

        this.$apollo.mutate({
          mutation: gql`
          mutation SingleImageUpload($refId: ID, $ref: String, $field: String, $info: FileInfoInput, $file: Upload!) {
             upload(refId: $refId, ref: $ref, field: $field, file: $file, info: $info) {
               data {
                 id
                 attributes {
                   url
                 }
               }
             }
          }`,
          variables: {
            file: e.target.files[0],
            "refId": this.edits.id,
            "ref": "api::fixture.fixture",
            "field": "Label",
            "info": {
                "name":"test",
                "alternativeText": "",
                "caption": "`"
            }
          },
          context: { headers: {
            'Authorization': 'Bearer ' + this.getJwtToken()
            }
          }
        }).then(function(res) {
          self.edits.Label = res.data.upload;
          self.$apollo.queries.fixtures.refetch();
        }).catch(function() {});
      },
      editFixture: function(fixture) {
        for (var k in this.edits) {
          if (k === 'id') this.edits[k] = fixture[k];
          else this.edits[k] = fixture.attributes[k];
        }

        this.openEdit();
      },
      deleteFixture: function(fixture) {
        var self = this;
        this.$apollo.mutate({
          mutation: gql`
          mutation($id:ID!){
            deleteFixture (id:$id){
              data {
                id
              }
            }
          }`,
          variables: {
            id: fixture.id
          },
          context: { headers: {
            'Authorization': 'Bearer ' + this.getJwtToken()
            }
          }
        }).then(function() {
          self.$apollo.queries.fixtures.refetch();
        });
      },
      openEdit() {
        this.editing = true;
        if (this.edits.id === '') {
          this.logMessage('info', false, 'Select the Appliance\'s Manufacturer.', function(offset) {
                self.messageOffset = offset;
          });
        }
      },
      clearAndCloseEdit() {
        this.clearEdit();
        this.closeEdit();
        this.logMessage('info', false, false, function(offset) {
                self.messageOffset = offset;
          });
      },
      clearEdit() {
        this.edits = initialEdits();
      },
      closeEdit() {
        this.editing = false;
      },
      onSubmit() {
        var self = this;
        if (this.edits.id !== '') {
          var decoderErrored = false;

          axios.get('https://homespy.io/api/decode', {
            headers: {
              'Authorization': 'Bearer ' + self.homespyToken,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            params: {
              'make': this.edits.Make,
              'serial': this.edits.Serial,
              'model': this.edits.Model
            }
          }).catch(function(){
            decoderErrored = true;
          }).then(function(res) {
            if (!decoderErrored) self.edits.Info = res.data.result.decoded;
            self.$apollo.mutate({
              mutation: gql`
              mutation updateFixture($id: ID!, $Make: String, $Model: String, $Serial: String, $Info: JSON){
                updateFixture(id: $id, data:{ Make: $Make, Model: $Model, Serial: $Serial, Info: $Info }) {
                  data {
                    id
                    attributes {
                      Make
                      Model
                      Serial
                      Info
                    }
                  }
                }
              }`,
              variables: {
                id: self.edits.id,
                Make: self.edits.Make,
                Model: self.edits.Model,
                Serial: self.edits.Serial,
                Info: self.edits.Info
              },
              context: { headers: {
                'Authorization': 'Bearer ' + self.getJwtToken()
                }
              }
            }).catch(function(){}).then(function() {
              self.$apollo.queries.fixtures.refetch();
            });
          });
        } else {
          if (self.idd) {
            self.$apollo.mutate({
              mutation: gql`
              mutation createFixture($id:ID!, $Make:String!, $Model:String, $Serial:String, $Label:ID, $Info: JSON) {
                createFixture(data:{property:$id,Make:$Make,Model:$Model,Serial:$Serial,Label:$Label,Info:$Info}) {
                  data {
                    attributes {
                      Make
                      Model
                      Serial
                      Info
                      Label {
                        data {
                          id
                        }
                      }
                      property {
                        data {
                          id
                        }
                      }
                    }
                  }
                }
              }`,
              variables: {
                id: self.property.id,
                Make: self.edits.Make,
                Model: self.edits.Model,
                Serial: self.edits.Serial,
                Info: self.edits.Info,
                Label: self.edits.Label.data.id
              },
              context: { headers: {
                'Authorization': 'Bearer ' + self.getJwtToken()
                }
              }
            }).then(function() {
              self.clearEdit();
              self.$apollo.queries.fixtures.refetch();
            });
          } else {
            axios.get('https://homespy.io/api/decode', {
              headers: {
                'Authorization': 'Bearer ' + self.homespyToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              params: {
                'make': self.edits.Make,
                'serial': self.edits.Serial,
                'model': self.edits.Model
              }
            }).then(function(res) {
              self.edits.Info = res.data.result.decoded;
              if (self.edits.Label) {
                self.$apollo.mutate({
                  mutation: gql`
                  mutation createFixture($id:ID!, $Make:String!, $Model:String!, $Serial:String!,$Label:ID, $Info: JSON) {
                    createFixture(data:{property:$id,Make:$Make,Model:$Model,Serial:$Serial,Label:$Label,Info:$Info}) {
                      data {
                        attributes {
                          Make
                          Model
                          Serial
                          Info
                          Label {
                            data {
                              id
                            }
                          }
                          property {
                            data {
                              id
                            }
                          }
                        }
                      }
                    }
                  }`,
                  variables: {
                    id: self.property.id,
                    Make: self.edits.Make,
                    Model: self.edits.Model,
                    Serial: self.edits.Serial,
                    Info: self.edits.Info,
                    Label: self.edits.Label.data.id
                  },
                  context: { headers: {
                    'Authorization': 'Bearer ' + self.getJwtToken()
                    }
                  }
                }).then(function() {
                  self.clearEdit();
                  self.$apollo.queries.fixtures.refetch();
                });
              } else {
                self.$apollo.mutate({
                  mutation: gql`
                  mutation createFixture($id:ID!, $Make:String!, $Model:String!, $Serial:String!, $Info: JSON) {
                    createFixture(data:{property:$id,Make:$Make,Model:$Model,Serial:$Serial,Info:$Info}) {
                      data {
                        attributes {
                          Make
                          Model
                          Serial
                          Info
                          property {
                            data {
                              id
                            }
                          }
                        }
                      }
                    }
                  }`,
                  variables: {
                    id: self.property.id,
                    Make: self.edits.Make,
                    Model: self.edits.Model,
                    Serial: self.edits.Serial,
                    Info: self.edits.Info
                  },
                  context: { headers: {
                    'Authorization': 'Bearer ' + self.getJwtToken()
                    }
                  }
                }).then(function() {
                  self.clearEdit();
                  self.$apollo.queries.fixtures.refetch();
                });
              }
            }).catch(function() {})
          }
        }

        this.closeEdit();
      },
      getJwtToken() {
        return this.$cookies.get('user-jwt');
      }
    },
    apollo: {
      fixtures: {
        query: gql` query getFixtures($id: String!){
          fixtures (filters: { property: { Title: {eq:$id} } }) {
            data{
              id
              attributes{
                Make
                Model
                Serial
                Info
                Label {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                property {
                  data {
                    id
                    attributes {
                      Title
                    }
                  }
                }
              }
            }
          }
        }`,
        variables() {
          return {
            id: this.$route.params.id
          }
        }, context() {
          return { headers: {
            "Authorization": "Bearer " + this.getJwtToken()
          }}
        }, result() {
          // var self = this;
          if (!this.editing) {
            if (this.fixtures.data && this.fixtures.data.length > 0) {
              // var self = this;
              this.logMessage('comp', false, 'Click ⋯ to edit | Click ✕ to delete.', function(offset) {
                self.messageOffset = offset;
          });
            } else {
              this.logMessage('info', false, 'Take a picture of your appliance\'s Manufacturer Label containing Model and Serial number. Click \'Add Fixture\' below. Select the Manufacturer. Choose File to upload picture. Wait for Model and Serial field to populate. Submit to save.', function(offset) {
                console.log(offset)
                self.messageOffset = offset;
          });
            }
          }
        }
      }
    }
  }
</script>

<style scoped>
  ul {
    margin-top:0;
  }
  li {
    list-style: none;
  }
  .property-header h1{
    margin-bottom: 0;
  }
  .property-header > *{
    display: inline-block;
  }
  .add-fixture {
    position: fixed;
    bottom:0px;
    width: 100vw;
    min-height:5em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.75);
  }
  .edit-fixture {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
  }
  .edit-fixture-background {
    position:absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    z-index: -1;
  }
  .edit-fixture form {
    padding: 2.5em;
    max-height:90vh;
    overflow: scroll;
    background-color: rgba(255,255,255,0.75);
    display:flex;
    flex-direction: column;
  }
  .decoding-loading {
    position: fixed;
    top: 0px;
    left:0px;
    right:0px;
    bottom:0px;
    background-color:rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>