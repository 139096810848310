<template>
<li
  v-for="property in allProperties"
  :key="property.attributes.Title"
>
<h1>
  <a @click="editProperty(property)"><sup>&#8943;</sup></a>&#xa0;
  <a :href="'/' + property.attributes.Title">
  {{ property.attributes.Title }}
  </a>
  &#xa0;<a @click="deleteProperty(property)"><sup>&#10005;</sup></a>

</h1>
</li>
</template>

<script>
export default {
  props: {
    properties: Object
  },
  emits:[
    'editProperty',
    'deleteProperty'
  ],
  computed: {
    allProperties() {
      return this.properties.data
    }
  },
  methods: {
    editProperty: function(property) {
      this.$emit('editProperty', property);
    },
    deleteProperty: function(property) {
      this.$emit('deleteProperty', property);
    }
  }
}
</script>

<style>
 h3 {
    margin-top:0 !important;
  }
ul {
  list-style-type: none;
  padding: 0;
}
li {
/*  display: inline-block;*/
  margin: 0 10px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  cursor: pointer;
  color: #42b983;
}
a :hover {
  text-decoration: underline;
}
li a {
  display: inline-block;
  text-decoration: none;
}
li button {
  margin-left:1em;
}
</style>
