import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createApolloProvider } from '@vue/apollo-option';
import apolloClient from './vue-apollo';
import Properties from './containers/Properties';
import Fixtures from './containers/Fixtures';
import OAuth from './components/OAuth';
import Logout from './components/Logout';
import VueCookies from "vue3-cookies";

import App from './App.vue';

var apolloProvider = createApolloProvider({
	defaultClient: apolloClient
});

var routes = [
	{ path: '/', component: Properties },
	{ path: '/:id(.*)', component: Fixtures },
  { path: '/logout', component: Logout },
	{ path: '/oauth', component: OAuth }
];

var router = createRouter({
	history: createWebHistory(),
	routes
});

document.title = 'BigPropTech';

createApp(App)
  .use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
  })
  .use(router)
  .use(apolloProvider)
  .mount('#app');
