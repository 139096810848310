<template>
  <LoadingIcon />
</template>

<script>
  import LoadingIcon from './LoadingIcon.vue';

  export default {
    components: {
      LoadingIcon
    },
    beforeMount() {
      this.$cookies.remove('user-jwt');
      window.location.href = '/';
    }
  };
</script>
