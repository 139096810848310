<template>
  <li
    v-for="fixture in allFixtures"
    :key="fixture.attributes.Serial"
  >
  <br/>

    <h3>
      <a @click="editFixture(fixture)"><sup>&#8943;</sup></a>&#xa0;
      <span v-if="!fixture.attributes.Info">
      <u>{{'Unknown Appliance'}}</u>
      </span>
      <span v-if="fixture.attributes.Info">
      <u>
      {{fixture.attributes.Info.details.type === undefined ? 'Unidentified ' : ''}}
      {{fixture.attributes.Info.make + (
        fixture.attributes.Info.details.type !== null ?
        ' ' + (fixture.attributes.Info.details.type || ' Appliance') :
        ' Appliance')
      }}</u>
      </span>
      &#xa0;<a @click="deleteFixture(fixture)"><sup>&#10005;</sup></a>
  </h3>
    <ul>
      <li><b>Age: </b>{{fixture.attributes.Info.mostLikelyYear ? (currentYear - fixture.attributes.Info.mostLikelyYear) + ' Years Old.' : 'Unknown'}}</li>
      <li><b>Model: </b>{{ fixture.attributes.Model }}</li>
      <li><b>Serial: </b>{{ fixture.attributes.Serial }}</li>
      <li v-show="fixture.attributes.Info.details.description"><b>Description: </b>{{fixture.attributes.Info.details.description}}</li>
      <!-- <li
        v-for="(value, key) in fixture.attributes.Identity"
        :key="key"
      >
        <b>{{key}}</b>: {{value}}
      </li> -->
    </ul>

    <!-- {{fixture.attributes.Info}} -->

  </li>

  <div style="height:4em"></div>
</template>

<script>
export default {
  data: function() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  props: {
    fixtures: Object
  },
  emits:[
    'editFixture',
    'deleteFixture'
  ],
  computed: {
    allFixtures() {
      return this.fixtures.data
    }
  },
  methods: {
    editFixture: function(fixture) {
      this.$emit('editFixture', fixture);
    },
    deleteFixture: function(fixture) {
      this.$emit('deleteFixture', fixture);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
