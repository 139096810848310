<template>
  <LoadingView />
</template>

<script>
  import axios from 'axios';
  import LoadingView from './LoadingIcon.vue';
  var self;

  export default {
    components: {
      LoadingView
    },
    methods: {
      async postGoogleOauthCode(code) {
        axios.post(process.env['VUE_APP_STRAPI_API_URL'] + '/strapi-google-auth/user-profile', {
          'code': code
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(function(response) {

          self.$cookies.set('user-jwt', response.data.data.token);
          window.location.href = '/';
        });
      }
    },
    beforeMount() {
      self = this;
      this.postGoogleOauthCode(this.$route.query.code);
    }
  };
</script>

<style>
</style>
