<template>
  <MessageDialog ref="message"></MessageDialog>
  <div :style="{height:((messageOffset + headerOffset)+'px')}" id="message-offset"></div>
  <ul>
    <PropertiesList 
      @editProperty="editProperty"
      @deleteProperty="deleteProperty"
      :properties="properties"
    ></PropertiesList>
    <li class="add-property" v-show="properties.data"><a @click="openEdit()">Add Property</a></li>
  </ul>
  <div v-if="editing" class="edit-property">
    <div @click="closeEdit()" class="edit-property-background"></div>
    <form @submit.prevent="onSubmit">
      <label for="title">Title:</label>
      <input id="title" v-model="edits.Title">
      <br/>
      <input type="submit" value="Submit">
      <br/>
    </form>
  </div>
</template>

<script>
  import { gql } from '@apollo/client';
  import PropertiesList from '../components/PropertiesList.vue';
  import MessageDialog from '../components/MessageDialog.vue';

  export default {
    components: {
      PropertiesList,
      MessageDialog
    },
    data() {
      return {
        properties: { data: false},
        editing: false,
        errored: false,
        messageOffset: 0,
        headerOffset: 0,
        edits: {
          id: '',
          Title: ''
        }
      }
    },
    beforeMount() {
      this.headerOffset = document.getElementById('user-controls').clientHeight;
    },
    mounted() {

    },
    methods: {
      logMessage: function(type, timeout, message) {
        var self = this;

        if (typeof this.$refs.message !== 'undefined') this.$refs.message.log(type, timeout, message,function(offset) {
                self.messageOffset = offset;
          });
      },
      editProperty: function(property) {
        for (var k in this.edits) {
          if (k === 'id') this.edits.id = property.id;
          else if (typeof property.attributes[k] !== 'undefined') {
            this.edits[k] = property.attributes[k];
          }
        }

        this.openEdit();
      },
      deleteProperty: function(property) {
        var self = this;
        this.$apollo.mutate({
          mutation: gql`
          mutation($id:ID!){
            deleteProperty (id:$id){
              data {
                id
              }
            }
          }`,
          variables: {
            id: property.id
          },
          context: { headers: {
            'Authorization': 'Bearer ' + this.getJwtToken()
            }
          }
        }).then(function() {
          self.$apollo.queries.properties.refetch();
        });
      },
      openEdit() {
        this.editing = true;
      },
      clearEdit() {
        for (var k in this.edits) {
          this.edits[k] = '';
        }
      },
      closeEdit() {
        this.clearEdit();
        this.editing = false;
      },
      onSubmit() {
        this.errored = false;
        var self = this;
        if (this.edits.id !== '') {
          this.$apollo.mutate({
            mutation: gql`
            mutation updateProperty($id: ID!, $Title: String){
              updateProperty(id:$id, data:{Title:$Title}) {
                data {
                  attributes {
                    Title
                  }
                }
              }
            }`,
            variables: {
              id: this.edits.id,
              Title: this.edits.Title
            },
            context: { headers: {
              'Authorization': 'Bearer ' + this.getJwtToken()
              }
            }
          }).then(function() {
            self.$apollo.queries.properties.refetch();
            this.closeEdit();
        this.edits.Title = '';
          });

        } else {
          this.$apollo.mutate({
            mutation: gql`
            mutation createProperty($Title:String!) {
              createProperty(data:{Title:$Title}) {
                data {
                  attributes {
                    Title
                  }
                }
              }
            }`,
            variables: {
              Title: this.edits.Title
            },
            context: { headers: {
              'Authorization': 'Bearer ' + this.getJwtToken()
              }
            }
          }).catch(function() {
            self.errored = true;
            self.logMessage('err', false, '"' + self.edits.Title + '" already exists. Please enter a unique Title.',function(offset) {
                self.messageOffset = offset;
          });
            self.closeEdit();
          })
          .finally(function() {
            if (!self.errored) window.location = '/' + self.edits.Title;
          });
        }
        
      },
      getJwtToken() {
        return this.$cookies.get('user-jwt');
      },
    },
    apollo: {
      properties: {
        query: gql` query getProperties{
          properties(sort:"Title") {
            data {
              id
              attributes {
                Title
              }
            }
          }
        }`, context() {
          return { headers: {
            'Authorization': 'Bearer ' + this.getJwtToken()
          }}
        }, result() {
          if (this.properties.data && this.properties.data.length > 0) {
            this.logMessage('comp', false, 'Click ⋯ to edit | View the Property\'s Fixtures. | Click ✕ to delete.');
          } else if (this.properties.data) {
            var self = this;
            this.logMessage('info', false, 'Click \'Add Property\' below to add a Property. Fill out Title ie. "Unique Street". Submit to save.',function(offset) {
                self.messageOffset = offset;
          });
          }
        }
      }
    }
  }
</script>

<style>
  .add-property {
    position: fixed;
    bottom:0px;
    width: 100vw;
    min-height:5em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.75);
  }
  li {
    list-style: none;
  }
  .property-header h1{
    margin-bottom: 0;
  }
  .edit-property {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
  }
  .edit-property-background {
    position:absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    z-index: -1;
  }
  .edit-property form {
    background-color: rgba(255,255,255,0.75);
    padding: 2.5em;
    display:flex;
    flex-direction: column;
  }
</style>