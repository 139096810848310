<template>
  <UserControls></UserControls>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
  import UserControls from './components/UserControls';
  export default {
    name: 'App',
    components: {
      UserControls
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
/*  margin-top: 60px;*/
}
body {
  margin: 0;
}
a {
  font-weight: bold;
}
</style>
