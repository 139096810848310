<template>
<div ref="el" v-show="Message" id="site-message">{{Message}}</div>
</template>

<script>
  import {defineExpose} from 'vue';
  var log = function() {}
  var offset = 0;
  export default {
    data() {
      return {
        Message: false
      }
    },
    methods: {
      // offset: self.$el.clientHeight,
      log: function(type, timeout, message, next) {
        var self = this;
        this.$refs.el.className = 'site-message--' + type;
        this.Message = message;
        if (timeout) {
          setTimeout(function() {
            self.Message = false;
          }, timeout);
        } else {
          this.$refs.el.addEventListener('click', function() {
            self.Message = false;
          })
        }

        setTimeout(function() {
          next(self.$el.clientHeight)
          // offset = 'self.$el.clientHeight';
        },50)
      }
    },
    mounted() {}
  }

  defineExpose({
    log,
    offset
  });
</script>

<style>
#site-message {
  width: 100vw;
  padding: 0.5em;
  position: fixed;
  z-index:1;
  top:3.5em;
  cursor: pointer;
}
#site-message.site-message--err {
  color: rgb(74, 20, 0);
  background-color:rgba(74, 20, 0,.1);
}
#site-message.site-message--comp {
  color: rgb(0, 74, 20);
  background-color:rgba(0, 74, 20,.1);
}
#site-message.site-message--warn {
  color: rgb(0, 0, 0);
  background-color:rgba(74, 74, 0,.1);
}
#site-message.site-message--info {
  color: rgb(20, 0, 74);
  background-color:rgba(20, 0, 74,.1);
}
#site-message.site-message--none {
  display: none;
}
</style>