import { ApolloClient } from "@apollo/client";
// import { createHttpLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client";
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

// var httpLink = createHttpLink({
// 	uri: process.env.VUE_APP_GRAPHQL_URL || 'http://localhost:1337/graphql'
// });

const httpLink = createUploadLink({
  uri: process.env.VUE_APP_GRAPHQL_URL || 'https://cheerful-excellence-fec4194c55.strapiapp.com/graphql'
});

var cache = new InMemoryCache();

var apolloClient = new ApolloClient({
	link: httpLink,
	cache
});

export default apolloClient;